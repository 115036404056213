import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
//STYLES
import { H3, LabelBW } from "../utils/typo"
import { ContainerMob, Section } from "../utils/utils"
//COMPONENTS
import Seo from "../components/seo/SEO"

const Linki = styled.div`
  display: flex;
  flex-direction: column;

  a {
    font-weight: 500;
  }
`

const Mapa = () => {
  return (
    <div>
      <Seo title="mapa strony" slug="/mapa" />
      <Section
        pt="6rem"
        pb="3rem"
        tabIndex="0"
        role="contentinfo"
        aria-label="Mapa strony"
      >
        <ContainerMob>
          <h1>Mapa strony</h1>
          <H3 as="h2">— tu jesteś</H3>

          <Linki>
            <LabelBW mt="3rem" mb="1rem">
              Menu
            </LabelBW>
            <Link to="/">— Produkt</Link>
            <Link to="/dzialanie">— Działanie</Link>
            <Link to="/projekt">— O projekcie</Link>
            <Link to="/kontakt">— Kontakt</Link>
            <LabelBW mt="3rem" mb="1rem">
              Informacje
            </LabelBW>
            <Link to="/projekt">— O projekcie</Link>
            <Link to="/">— Deklaracja dostępności</Link>
            <Link to="/">— Polityka prywatności</Link>
          </Linki>
        </ContainerMob>
      </Section>
    </div>
  )
}
export default Mapa
